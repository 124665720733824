//Modules
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//Components
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Space from './pages/Space'
import Header from './components/Header'
import SpacesList from './pages/SpacesList'
import SpaceSingle from './pages/SpaceSingle'
import UsersList from './pages/UsersList'
import Profile from './pages/Profile'
import Reservations from './pages/Reservations'
// import { SnowflakeCursor } from './utils/SnowflakeCursor'

function App() {

  return (
    <>
      {/* <SnowflakeCursor /> */}
      <Router>
        <div className='container mx-auto'>
          <Header />
          <Routes>            
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/users' element={<UsersList />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/reservations' element={<Reservations />} />
            <Route path='/spaces' element={<SpacesList />} />
            <Route path='/spaces/:id' element={<SpaceSingle />} />
            <Route path='/space/:id/:date?' element={<Space />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer
        position='top-right'
        autoClose={1100}
        closeOnClick={true}
        pauseOnFocusLoss={false}
       />
    </>
  );
}

export default App;
